import styles from "./Payment.module.scss";
import { FC, useEffect, useState } from "react";
import Offcanvas from "@/components/offcanvas/Offcanvas";
import PaymentProgressBar from "@/components/payment-progress-bar/PaymentProgressBar";
import PaymentPlans from "@/components/payment-plans/PaymentPlans";
import PaymentStripe from "@/components/payment-stripe/PaymentStripe";
import PaymentWelcome from "@/components/payment-welcome/PaymentWelcome";
import Image from "next/image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBadgeCheck, faPlus, faStar, faStarShooting } from "@fortawesome/pro-solid-svg-icons";
import { plansFeatures } from "@/lib/vars";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { codeAppliedState, darkerBackgroundColorState, lighterBackgroundColorState, paymentModalOpenState, planState, subscriptionState, textColorState, themeState, userDataState } from "@/lib/store";
import { Box, Button, ButtonGroup, Paper, Typography, useMediaQuery } from "@mui/material";
import { classNames } from "@/lib/utils";
import { useRouter } from "next/router";

interface PropsTypes {
    noCloseButton?: boolean;
    setIsOpen?: (isOpen: boolean) => void;
    isAppSubscriber?: boolean;
    hidden?: boolean;
}

export const PaymentFlow: FC<PropsTypes> = ({ noCloseButton, setIsOpen, isAppSubscriber }) => {
    const userData = useRecoilValue(userDataState);
    const theme = useRecoilValue(themeState);
    const lighterBackgroundColor = useRecoilValue(lighterBackgroundColorState);
    const darkerBackgroundColor = useRecoilValue(darkerBackgroundColorState);
    const textColor = useRecoilValue(textColorState);

    const [step, setStep] = useState(1);

    const [payment, setPayment] = useState({});
    const [disabled, setDisabled] = useState(false);
    const subscription = useRecoilValue(subscriptionState);
    const [plan, setPlan] = useRecoilState(planState);
    const codeApplied = useRecoilValue(codeAppliedState);

    const [lastNameInvalid, setLastNameInvalid] = useState(false);
    const [firstNameInvalid, setFirstNameInvalid] = useState(false);

    const { query } = useRouter();
    const [interval, setInterval] = useState<"annually" | "monthly">(query.pt === "m" ? "monthly" : "annually");

    const isPlanBasic = plan?.product_name.includes("Basic");
    const isDisabled = step === 2 && (disabled || firstNameInvalid || lastNameInvalid);
    const [discountInfo, setDiscountInfo] = useState<any>();

    useEffect(() => {
        if (isAppSubscriber || codeApplied?.toUpperCase() === "FEMALEINVEST") {
            setStep(2);
            setPlan(subscription.plans.find((plan) => plan.product_name.includes("Advanced") && plan.plan_interval === interval));
        } else {
            setStep(1);
        }
    }, [isAppSubscriber, codeApplied]);

    const handleIntervalChange = (newInterval: "annually" | "monthly") => {
        setInterval(newInterval);
        setPlan(subscription.plans.find((plan) => plan.product_name.includes("Advanced") && plan.plan_interval === newInterval));
    };

    const onClick = () => {
        switch (step) {
            case 1:
                setStep(2);
                break;
            case 2:
                setPayment({});
                break;
            case 3:
                setIsOpen(false);
                window.location.href = `${window.location.origin}/markets-overview?appcue=320b8564-b0a8-4871-b565-4235bd41685c`;
        }
    };

    useEffect(() => {
        const newDiscountInfo = subscription?.payment_data?.plans?.find((p) => p.id === plan?.plan_id);

        if (newDiscountInfo) {
            setDiscountInfo(newDiscountInfo);
        }
    }, [plan]);

    const getButtonText = () => {
        switch (step) {
            case 1:
                return "Checkout";
            case 2:
                return "Complete your payment";
            case 3:
                return "Begin your journey";
        }
    };

    return (
        <>
            <div className={styles.logo}>
                <Image unoptimized src="/assets/ortex-logo-h.png" alt="ortex-logo" width={150} height={30} priority />
            </div>

            <div className={classNames(styles.Payment, isAppSubscriber && styles.isAppSubscriber)}>
                <Box className={styles.container}>
                    {step === 1 && <PaymentPlans interval={interval} plan={plan} plans={subscription.plans} setInterval={setInterval} setPlan={setPlan} setStep={setStep} onClose={setIsOpen} />}
                    {step === 2 && (
                        <>
                            {isAppSubscriber && (
                                <Box className={styles.appSuscriberText}>
                                    <Box>
                                        <Typography variant="h1" component="h1" className={styles.title}>
                                            {userData?.first_name}, welcome to the web version of ORTEX
                                        </Typography>
                                        <Typography variant="body1" component="p" className={styles.subtitle}>
                                            To get full access to the web version you need to upgrade your account.
                                        </Typography>
                                        <Typography variant="body1" component="p" className={styles.subtitle}>
                                            As an app user you get a <b>50% off</b> your initial purchase.
                                        </Typography>
                                    </Box>
                                    <ButtonGroup size="small" sx={{ m: "0 auto", mt: 2 }}>
                                        <Button onClick={() => handleIntervalChange("annually")} variant={interval === "annually" ? "contained" : "outlined"} sx={{ textTransform: "initial", color: textColor, fontSize: "16px" }}>
                                            Yearly
                                        </Button>
                                        <Button onClick={() => handleIntervalChange("monthly")} variant={interval === "annually" ? "outlined" : "contained"} sx={{ textTransform: "initial", color: textColor, fontSize: "16px" }}>
                                            Monthly
                                        </Button>
                                    </ButtonGroup>
                                </Box>
                            )}
                            <PaymentStripe
                                key={codeApplied}
                                payment={payment}
                                setStep={setStep}
                                disabled={disabled}
                                setDisabled={setDisabled}
                                firstNameInvalid={firstNameInvalid}
                                setFirstNameInvalid={setFirstNameInvalid}
                                lastNameInvalid={lastNameInvalid}
                                setLastNameInvalid={setLastNameInvalid}
                            />
                        </>
                    )}
                    {step === 3 && <PaymentWelcome plan={plan} closeModal={setIsOpen} />}
                </Box>
                <Paper elevation={3} className={styles["right-container"]} sx={{ backgroundColor: theme === "dark" ? lighterBackgroundColor : darkerBackgroundColor }}>
                    <Box>
                        <Box>
                            <PaymentProgressBar number={step} />
                        </Box>
                        <Box className={styles.title}>
                            <FontAwesomeIcon icon={isPlanBasic ? faStar : faStarShooting} color="#31aba6" className={styles["star-icon"]} />
                            <Box>
                                <Typography variant="body1" component="p" sx={{ fontSize: "20px" }}>
                                    {plan?.product_name} Plan
                                </Typography>
                                {!(userData?.app_subscriber_only || codeApplied?.toUpperCase() === "FEMALEINVEST") && (
                                    <Typography variant="body1" component="p" sx={{ fontSize: "11px", color: theme === "dark" ? "#939598" : "#3f3e40" }}>
                                        Billed {plan?.plan_interval} at {plan?.currency_symbol}
                                        {plan?.plan_price}
                                    </Typography>
                                )}
                                {codeApplied ? (
                                    <Typography variant="body1" component="p" sx={{ fontSize: "11px", color: theme === "dark" ? "#939598" : "#3f3e40" }}>
                                        {userData?.app_subscriber_only || codeApplied?.toUpperCase() === "FEMALEINVEST" ? (
                                            <>
                                                Discount applied - First {plan?.plan_interval === "monthly" ? "month" : "year"} at {plan?.currency_symbol} {plan?.plan_price}
                                                <br />
                                                After that: {plan?.currency_symbol}
                                                {discountInfo?.original_price} per {plan?.plan_interval === "monthly" ? "month" : "year"}
                                            </>
                                        ) : (
                                            discountInfo?.original_price && (
                                                <>
                                                    <span className={styles.discount}>Discount applied</span>
                                                    {discountInfo?.duration_in_months ? `, valid for ${discountInfo.duration_in_months} ${discountInfo.duration_in_months === 1 ? "month" : "months"}` : ""}, original price {plan?.currency_symbol}
                                                    {discountInfo?.original_price}
                                                </>
                                            )
                                        )}
                                    </Typography>
                                ) : null}
                            </Box>
                        </Box>
                        {!isAppSubscriber && !isPlanBasic && (
                            <Box className={styles.advanced}>
                                <Typography variant="body1" component="p" sx={{ marginRight: "5px" }}>
                                    Everything in Basic
                                </Typography>
                                <FontAwesomeIcon icon={faPlus} color="#31aba6" height={20} />
                            </Box>
                        )}
                        {isAppSubscriber && (
                            <Box className={styles.advanced}>
                                <Typography variant="body1" component="p" sx={{ marginRight: "5px" }}>
                                    App Access
                                </Typography>
                                <FontAwesomeIcon icon={faPlus} color="#31aba6" height={20} />
                            </Box>
                        )}
                        {(plansFeatures[plan?.product_name] || plansFeatures["ORTEX Basics"]).map((feature) => (
                            <Box key={feature.text} className={styles.feature}>
                                <FontAwesomeIcon icon={faBadgeCheck} color="#31aba6" height={20} />
                                <Typography variant="body1" component="p" sx={{ marginLeft: "10px" }}>
                                    {feature.text}
                                </Typography>
                            </Box>
                        ))}
                        {step === 1 && isPlanBasic && (
                            <Box className={styles.upgrade}>
                                <Typography variant="body1" component="p">
                                    Don't miss out - Upgrade to <span onClick={() => setPlan(subscription.plans.find((plan) => plan.product_name.includes("Advanced") && plan.plan_interval === interval))}>Advanced</span>
                                </Typography>
                            </Box>
                        )}
                        <hr />
                        {step !== 3 && userData?.subscription_plan === "ORTEX Basic" && (
                            <Box className={styles.invoice}>
                                <Typography variant="body1">Your first invoice will be reduced by the existing balance from your current subscription</Typography>
                            </Box>
                        )}
                        <Button className={styles.btn} variant="contained" sx={{ textTransform: "initial" }} disabled={isDisabled} onClick={onClick}>
                            {getButtonText()}
                        </Button>
                    </Box>
                </Paper>
            </div>
        </>
    );
};

const Payment: FC<PropsTypes> = ({ noCloseButton, hidden }) => {
    const [paymentModalOpen, setPaymentModalOpen] = useRecoilState(paymentModalOpenState);

    return (
        <div>
            <Offcanvas isOpen={paymentModalOpen} onClose={setPaymentModalOpen} noCloseButton={noCloseButton} hidden={hidden}>
                <PaymentFlow noCloseButton={noCloseButton} setIsOpen={setPaymentModalOpen} />
            </Offcanvas>
        </div>
    );
};

export default Payment;
