import styles from "./PaymentStripeForm.module.scss";
import { Dispatch, FC, FormEvent, FormEventHandler, MouseEvent, SetStateAction, useEffect, useRef, useState } from "react";
import { userDataState, lighterBackgroundColorState, themeState, codeAppliedState, promoCodeDisabledState } from "@/lib/store";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useRecoilState, useRecoilValue } from "recoil";
import { toast } from "react-toastify";
import { cancelBasic } from "@/controllers/subscription";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { Plan } from "@/models/subscription";
import { classNames } from "@/lib/utils";
import useApplyPromoCode from "src/hooks/useApplyPromoCode";

declare const gtag: any;

interface PropsTypes {
    payment: {};
    setStep: (step: number) => void;
    disabled: boolean;
    setDisabled: Dispatch<SetStateAction<boolean>>;
    firstNameInvalid: boolean;
    setFirstNameInvalid: Dispatch<SetStateAction<boolean>>;
    lastNameInvalid: boolean;
    setLastNameInvalid: Dispatch<SetStateAction<boolean>>;
    plan: Plan;
}

const PaymentStripeForm: FC<PropsTypes> = ({ payment, setStep, disabled, setDisabled, firstNameInvalid, setFirstNameInvalid, lastNameInvalid, setLastNameInvalid, plan }) => {
    const stripe = useStripe();
    const elements = useElements();

    const userData = useRecoilValue(userDataState);
    const theme = useRecoilValue(themeState);
    const lighterBackground = useRecoilValue(lighterBackgroundColorState);
    const { applyPromoCode } = useApplyPromoCode();
    const [card, setCard] = useState<{ brand: string; last4: string }>();

    const firstNameRef = useRef<HTMLInputElement>();
    const lastNameRef = useRef<HTMLInputElement>();
    const discountCodeRef = useRef<HTMLInputElement>();
    const firstLoad = useRef(true);

    const isDisabled = disabled || firstNameInvalid || lastNameInvalid;

    const [promoCodeDisabled, setPromoCodeDisabled] = useRecoilState(promoCodeDisabledState);
    const codeApplied = useRecoilValue(codeAppliedState);
    const [promoCode, setPromoCode] = useState(codeApplied);

    useEffect(() => {
        setPromoCodeDisabled(!promoCode.length || !!codeApplied.length);
    }, [promoCode]);

    useEffect(() => {
        if (!card && userData) {
            firstNameRef.current.value = userData?.first_name;
            lastNameRef.current.value = userData?.last_name;
        }
    }, [card, userData]);

    useEffect(() => {
        if (firstLoad.current) firstLoad.current = false;
        else onSubmit();
    }, [payment]);

    const doPayment = () => {
        if (process.env.NEXT_PUBLIC_VERCEL_ENV)
            stripe
                .confirmPayment({
                    elements,
                    redirect: "if_required",
                    confirmParams: {
                        payment_method_data: {
                            billing_details: {
                                email: userData.email,
                                name: `${firstNameRef.current?.value} ${lastNameRef.current?.value}`,
                            },
                        },
                    },
                })
                .then((res) => {
                    if (res.error) toast(res.error.message, { type: "error" });
                    else {
                        try {
                            (window as any).dataLayer.push({
                                event: "purchase",
                                ecommerce: {
                                    transaction_id: res.paymentIntent.id,
                                    value: plan.plan_price,
                                    currency: plan.plan_currency,
                                    items: [
                                        {
                                            item_id: `plan-${plan.plan_id}`,
                                            item_name: `plan-${plan.plan_id}-${plan.plan_interval}`,
                                            price: plan.plan_price,
                                            quantity: 1,
                                        },
                                    ],
                                },
                            });
                        } catch (err) {
                            console.error(err);
                        }
                        try {
                            gtag("event", "conversion", {
                                send_to: "AW-692704651/rEvnCLy7qMUDEIurp8oC",
                                value: plan.plan_price,
                                currency: plan.plan_currency,
                            });
                        } catch (err) {
                            console.error(err);
                        }
                        setStep(3);
                    }
                })
                .finally(() => setDisabled(false));
        else setStep(3);
    };

    const onSubmit = (e?: MouseEvent | FormEvent) => {
        e?.preventDefault();
        setDisabled(true);
        if (userData.subscription_plan === "ORTEX Basic")
            cancelBasic()
                .then(doPayment)
                .catch(() => setDisabled(false));
        else doPayment();
    };

    const onInvalid: FormEventHandler<HTMLFormElement> = (e) => {
        if ((e.target as HTMLInputElement).id === "firstName") setFirstNameInvalid(true);
        if ((e.target as HTMLInputElement).id === "lastName") setLastNameInvalid(true);
    };

    return (
        <div className={styles.PaymentStripeForm}>
            <form onSubmit={onSubmit} onInvalid={onInvalid}>
                {!userData?.app_subscriber_only && (
                    <button className={styles.backBtn} onClick={() => setStep(1)}>
                        <FontAwesomeIcon icon={faAngleLeft} color="#808080" height={30} />
                        Go back
                    </button>
                )}
                {card ? (
                    <div className={styles.card}>
                        <p>
                            The subscription will be charged to your {card.brand.toUpperCase()} card that ends with {card.last4}
                        </p>
                        <button className={styles.card} onClick={() => setCard(null)} style={{ border: `2px solid ${lighterBackground}` }}>
                            Change Card
                        </button>
                    </div>
                ) : (
                    <>
                        <div>
                            <label>
                                First Name
                                <input
                                    id="firstName"
                                    placeholder="John"
                                    ref={firstNameRef}
                                    required
                                    className={classNames(styles[firstNameInvalid && "invalid"], theme === "dark" ? styles.dark : styles.light)}
                                    onChange={(e) => setFirstNameInvalid(!e.target.checkValidity())}
                                />
                            </label>
                            <label>
                                Last Name
                                <input
                                    id="lastName"
                                    placeholder="Doe"
                                    ref={lastNameRef}
                                    required
                                    className={classNames(styles[lastNameInvalid && "invalid"], theme === "dark" ? styles.dark : styles.light)}
                                    onChange={(e) => setLastNameInvalid(!e.target.checkValidity())}
                                />
                            </label>

                            {codeApplied && !(userData.app_subscriber_only || promoCode?.toUpperCase() === "FEMALEINVEST") ? (
                                <div className={styles.promo}>
                                    <label>
                                        Promo code
                                        <input
                                            id="discountCode"
                                            ref={discountCodeRef}
                                            value={promoCode}
                                            onChange={(e) => setPromoCode(e.target.value)}
                                            onKeyDown={(e) => {
                                                e.key === "Enter" && (e.preventDefault(), applyPromoCode(promoCode));
                                            }}
                                            placeholder={promoCode}
                                            className={classNames(theme === "dark" ? styles.dark : styles.light)}
                                        />
                                    </label>
                                    <button className={styles.applyPromoBtn} type="button" onClick={() => applyPromoCode()} disabled={promoCodeDisabled}>
                                        Apply
                                    </button>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                        <PaymentElement onChange={(e) => setDisabled(!e.complete)} />

                        <button type="button" disabled={isDisabled} onClick={onSubmit}>
                            Complete your purchase
                        </button>
                    </>
                )}
            </form>
        </div>
    );
};

export default PaymentStripeForm;
