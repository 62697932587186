import { useContext } from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Drawer, FormControl, IconButton, InputAdornment, OutlinedInput, Chip, useMediaQuery, Button, Typography } from "@mui/material";
import { FC, KeyboardEvent, KeyboardEventHandler, useState, useRef, useEffect } from "react";

import { findInstruments } from "@/controllers/find-instruments";
import { FindInstruments } from "@/models/find-instruments";
import HeaderAutocomplete from "@/components/header-autocomplete/HeaderAutocomplete";
import LoadingSpinner from "@/components/loading-spinner/LoadingSpinner";
import UniverseSelect from "@/components/universe-select/UniverseSelect";
import {
    themeState,
    userState,
    backgroundColorState,
    greyDarkerState,
    whiteMediumState,
    textColorState,
    greyLighterState,
    paymentModalOpenState,
    ortexColorState,
    userDataState,
    codeAppliedState,
    subscriptionState,
    showAppSubscriberModalState,
    planState,
} from "@/lib/store";
import Menu from "../menu/Menu";
import UserMenu from "../user-menu/UserMenu";
import Notifications from "../notifications/Notifications";
import HeaderCustomViews from "../header-custom-views/HeaderCustomViews";

import styles from "./Header.module.scss";
import { Stock } from "@/models/stock";
import StockLinks from "../stock-links/StockLinks";
import { classNames } from "@/lib/utils";
import { TextLoop } from "react-text-loop-next";
import { Context } from "@/lib/Context";
import { restartSubscription } from "@/controllers/subscription";
import useOpenSubscriptionForm from "src/hooks/useOpenSubscriptionForm";

let searchTimeout: NodeJS.Timeout;

interface PropsTypes {
    ticker: string;
    exchange: string;
    stock?: Stock;
    scrolledDown: boolean;
}

type Anchor = "top" | "left" | "bottom" | "right";

const Header: FC<PropsTypes> = ({ ticker, exchange, stock, scrolledDown }) => {
    const { openSubscriptionForm } = useOpenSubscriptionForm();

    const textColor = useRecoilValue(textColorState);
    const greyLighter = useRecoilValue(greyLighterState);
    const ortexColor = useRecoilValue(ortexColorState);
    const theme = useRecoilValue(themeState);
    const greyDarker = useRecoilValue(greyDarkerState);
    const whiteMedium = useRecoilValue(whiteMediumState);
    const backgroundColor = useRecoilValue(backgroundColorState);
    const user = useRecoilValue(userState);
    const userData = useRecoilValue(userDataState);
    const [searchHistory, setSearchHistory] = useState<FindInstruments[]>([]);
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const isMobile = useMediaQuery("(max-width: 768px)");
    const isTablet = useMediaQuery("(max-width: 1024px)");
    const is4k = useMediaQuery("(min-width: 3840px)");

    const [searchBox, setSearchBox] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [activeIndex, setActiveIndex] = useState<number>(null);
    const [instruments, setInstruments] = useState<FindInstruments[]>([]);
    const [searchValue, setSearchValue] = useState("");
    const [isVisitor, setIsVisitor] = useState<boolean>();
    const [showPlaceholder, setShowPlaceholder] = useState(true);
    const searchInputRef = useRef<HTMLInputElement>();

    const setSubscription = useSetRecoilState(subscriptionState);
    const setPlan = useSetRecoilState(planState);
    const setCodeApplied = useSetRecoilState(codeAppliedState);
    const setShowAppSubscriberModal = useSetRecoilState(showAppSubscriberModalState);

    const { push } = useRouter();
    const router = useRouter();

    const [paymentModalOpen, setPaymentModalOpen] = useRecoilState(paymentModalOpenState);

    const placeholder =
        ticker && exchange ? (
            `${exchange}:${ticker}`
        ) : (
            <div className={styles.placeholderText}>
                <Typography variant="subtitle2" color={textColor}>
                    Search{" "}
                </Typography>
                &nbsp;
                <TextLoop>
                    <Typography variant="subtitle2">
                        <span className={styles.textLoopText}>ORTEX</span>
                    </Typography>
                    <Typography variant="subtitle2" color={textColor}>
                        <span>Stocks</span>
                    </Typography>
                    <Typography variant="subtitle2" color={textColor}>
                        <span>ETF's</span>
                    </Typography>
                    <Typography variant="subtitle2" color={textColor}>
                        <span>Tickers</span>
                    </Typography>
                </TextLoop>
            </div>
        );

    useEffect(() => {
        if (user["subscription_plan"] === "ORTEX Visitor") {
            setIsVisitor(true);
        } else {
            setIsVisitor(false);
        }
    }, [user]);

    const upgradeButtonText = {
        "ORTEX Free": "Upgrade",
        "ORTEX Basic": "Get Advanced",
        "ORTEX Visitor": "Create free account",
    };

    useEffect(() => {
        setSearchHistory(user?.settings?.search_settings.map((instrument) => ({ ...instrument, is_history: true })));
    }, [user?.settings?.search_settings]);

    const getInstruments = (event: KeyboardEvent<HTMLInputElement>) => {
        setLoading(true);
        clearTimeout(searchTimeout);

        searchTimeout = setTimeout(() => {
            findInstruments(event.target["value"]).then((data) => {
                setInstruments([...data, ...searchHistory]);
                if (data.length) setActiveIndex(0);
                setLoading(false);
            });
        }, 500);
    };

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
        switch (event.key) {
            case "Enter":
                if (instruments.length > 0 && activeIndex !== null) {
                    push(`/s/${instruments[activeIndex].exchange || " ‎"}/${instruments[activeIndex].ticker}`);
                    setSearchBox(false);
                    setActiveIndex(null);
                    searchInputRef.current?.blur();
                }
                break;
            case "ArrowDown":
                if (instruments.length > 0 && activeIndex === null) {
                    setActiveIndex(0);
                } else if (instruments.length > 0 && activeIndex !== null) {
                    setActiveIndex(activeIndex !== instruments.length - 1 ? activeIndex + 1 : 0);
                }
                break;
            case "ArrowUp":
                if (instruments.length > 0 && activeIndex !== null) {
                    setActiveIndex(activeIndex !== 0 ? activeIndex - 1 : null);
                }
                break;
            default:
                setSearchBox(true);
                setActiveIndex(null);
                getInstruments(event);
                break;
        }
    };

    const handleInputFocus = () => {
        setTimeout(() => {
            setSearchBox(true);
            setShowPlaceholder(false);
        }, 250);

        setTimeout(() => {
            setInstruments(searchHistory);
        }, 500);
    };

    const handleInputBlur = () => {
        setTimeout(() => {
            setSearchValue("");
            setSearchBox(false);
            setInstruments([]);
            setShowPlaceholder(true);
        }, 250);
    };

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
            return;
        }
        setSearchBox(true);
        setState({ ...state, [anchor]: open });
    };

    useEffect(() => {
        if (isTablet || isMobile) {
            router.events.on("routeChangeStart", () => {
                handleInputBlur();
                setState({ ...state, top: false });
            });
        }
    }, [isMobile, isTablet]);

    const handleUpdate = () => {
        setPaymentModalOpen(true);
        openSubscriptionForm();

        const interval = (router.query.pt as string) === "m" ? "monthly" : "annually";

        if (userData?.app_subscriber_only) {
            restartSubscription("APPDISC")
                .then((res) => {
                    setCodeApplied("APPDISC");
                    setSubscription(res);
                    setPlan(res.plans.find((plan) => plan.product_name.includes("Advanced") && plan.plan_interval === interval));
                })
                .then(() => {
                    setShowAppSubscriberModal(true);
                    setTimeout(() => {
                        setPaymentModalOpen(false);
                    }, 1500);
                });
        }
    };

    return (
        <>
            <header
                className={styles.Header}
                style={{
                    backgroundColor: isMobile && !scrolledDown ? "transparent" : backgroundColor,
                    visibility: user.email ? "visible" : "hidden",
                    borderBottom: isMobile ? "none" : `1px solid ${theme === "dark" ? greyDarker : theme === "light" ? whiteMedium : "unset"}`,
                    transition: "background-color 0.5s ease",
                }}
            >
                <div>
                    <Menu />
                    {isMobile ? (
                        <Box>
                            <Link href="/" passHref>
                                <IconButton className={styles.logo}>
                                    <Image unoptimized src="/assets/ortex-icon.png" alt="ortex-logo" width={20} height={20} priority />
                                </IconButton>
                            </Link>
                        </Box>
                    ) : (
                        <Box sx={{ cursor: "pointer" }}>
                            <Link href="/" passHref>
                                <a>
                                    <Image unoptimized src="/assets/ortex-logo-h.png" alt="ortex-logo-h" width={145} height={30} priority />
                                </a>
                            </Link>{" "}
                        </Box>
                    )}

                    <div className={classNames(styles.middleSection, searchBox ? styles.searchStarted : "")}>
                        <Box>
                            {isMobile || isTablet ? (
                                <div className={styles.mobileSearch}>
                                    <IconButton aria-label="search" style={{ color: textColor }} onClick={toggleDrawer("top", !state["top"])}>
                                        <SearchIcon />
                                    </IconButton>

                                    <Drawer
                                        anchor="top"
                                        open={state["top"]}
                                        onClose={toggleDrawer("top", false)}
                                        sx={{
                                            zIndex: 6,
                                            "& .MuiDrawer-paperAnchorTop": {
                                                top: `calc(40px + env(safe-area-inset-top) + ${is4k ? "40px" : "25px"})`,
                                            },
                                        }}
                                        className={styles.expo}
                                    >
                                        <div className={styles.mobileSearchDrawer}>
                                            <div className={styles.mobileSearchDrawerContent}>
                                                <FormControl size="small" fullWidth sx={{ mt: 1, mb: 1, display: "flex", flex: 1, width: "unset", border: 0 }}>
                                                    <OutlinedInput
                                                        type="text"
                                                        inputRef={searchInputRef}
                                                        onKeyDown={handleKeyDown}
                                                        onFocus={handleInputFocus}
                                                        autoCapitalize="none"
                                                        onChange={(event) => setSearchValue(event.target.value)}
                                                        value={searchValue}
                                                        sx={{
                                                            width: "100%",
                                                        }}
                                                        id="outlined-adornment-amount"
                                                        className={styles.searchInput}
                                                        startAdornment={
                                                            <InputAdornment position="start">
                                                                <SearchIcon htmlColor={greyLighter} />
                                                            </InputAdornment>
                                                        }
                                                        autoComplete="off"
                                                        name="search"
                                                    />
                                                    {showPlaceholder && <div className={styles.placeholder}>{placeholder}</div>}
                                                </FormControl>
                                                {searchBox && instruments.length > 0 && <HeaderAutocomplete instruments={instruments} activeIndex={activeIndex} onMouseEnter={() => setActiveIndex(null)} />}
                                                {loading && (
                                                    <div className={styles.loader}>
                                                        <LoadingSpinner size={18} borderWidth={2} color={greyLighter} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Drawer>
                                </div>
                            ) : (
                                <div className={styles.search}>
                                    <div className={styles.backdrop}></div>
                                    <FormControl size="small" fullWidth sx={{ mt: 1, mb: 1, display: "flex", flex: 1, width: "unset", border: 0 }}>
                                        <OutlinedInput
                                            id="outlined-adornment-amount"
                                            value={searchValue}
                                            className={styles.searchInput}
                                            onChange={(event) => setSearchValue(event.target.value)}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <SearchIcon htmlColor={textColor} />
                                                </InputAdornment>
                                            }
                                            onKeyDown={handleKeyDown}
                                            onFocus={handleInputFocus}
                                            onBlur={handleInputBlur}
                                            autoCapitalize="none"
                                            autoComplete="off"
                                            name="search"
                                        />
                                        {showPlaceholder && (
                                            <div className={styles.placeholder}>
                                                <Typography variant="subtitle2"> {placeholder}</Typography>
                                            </div>
                                        )}
                                    </FormControl>
                                    {searchBox && instruments.length > 0 && <HeaderAutocomplete instruments={instruments} activeIndex={activeIndex} onMouseEnter={() => setActiveIndex(null)} />}
                                </div>
                            )}
                        </Box>
                        <div className={styles.universe}>
                            <UniverseSelect />
                        </div>
                    </div>
                    {["ORTEX Free", "ORTEX Basic", "ORTEX Visitor"].includes(user?.subscription_plan) && !isMobile && (
                        <Button variant="outlined" size="small" onClick={() => (user.subscription_plan === "ORTEX Visitor" ? push("/register") : handleUpdate())} sx={{ minWidth: "80px" }}>
                            {paymentModalOpen ? <LoadingSpinner size={20} borderWidth={2} color={ortexColor} /> : upgradeButtonText[user?.subscription_plan]}
                        </Button>
                    )}

                    <HeaderCustomViews />
                    <Notifications />
                    <div className={styles.user}>
                        <UserMenu firstName={user.first_name} lastName={user.last_name} isVisitor={isVisitor} />
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
