import { Cancellation, CancellationBody, GetAnnualPrice, PaymentBody, PaymentResponse, Promo, Subscription } from "@/models/subscription";
import axios from "axios";
import dayjs from "dayjs";

export const restartSubscription = async (promoCode?) => {
    try {
        if (!process.env.NEXT_PUBLIC_VERCEL_ENV) {
            const res = await Promise.resolve({
                payment_data: {
                    stripe_client_secret: "seti_1LGO94INrmKXpRLljpKXF6WS_secret_LyKoJwQymWWfIgtrxYjCnhYdLDdZUUM",
                    stripe_public_key: "pk_test_LqVdXoPabC9CkARbFE9o4vZC000J8BDipM",
                    plans: [
                        {
                            product_name: "ORTEX Advanced",
                            id: "price_1L0nBKINrmKXpRLl1J1biOC2",
                            currency: "eur",
                            price: promoCode ? 65 : 588,
                            plan_interval: "annually",
                            discount_code: "JULY4",
                            original_price: promoCode ? 111 : 588,
                            discount_value: 144,
                            discount_pc: 24.49,
                        },
                        {
                            product_name: "ORTEX Advanced",
                            id: "price_1L0n9iINrmKXpRLl6FRVltA2",
                            currency: "eur",
                            price: 79,
                            original_price: promoCode ? 111 : 588,
                            plan_interval: "monthly",
                        },
                        {
                            product_name: "ORTEX Basic",
                            id: "price_1L0nCkINrmKXpRLlG0ptAXUf",
                            currency: "eur",
                            price: 276,
                            plan_interval: "annually",
                        },
                        {
                            product_name: "ORTEX Basic",
                            id: "price_1L0nBzINrmKXpRLlAlG0eFgb",
                            currency: "eur",
                            price: 29,
                            plan_interval: "monthly",
                        },
                    ],
                    subscriptions: null,
                },
                plans: [
                    {
                        plan_id: "price_1L0nBKINrmKXpRLl1J1biOC2",
                        plan_currency: "eur",
                        plan_price: promoCode ? 111 : 588,
                        coupon: null,
                        plan_interval: "annually",
                        currency_symbol: "€",
                        plan_header: null,
                        product_name: "ORTEX Advanced",
                        plan_desc: "",
                        discount_code: "JULY4",
                        original_price: promoCode ? 111 : 588,
                        discount_value: 144,
                        currency: "eur",
                        discount_pc: 24.49,
                    },
                    {
                        plan_id: "price_1L0n9iINrmKXpRLl6FRVltA2",
                        plan_currency: "eur",
                        plan_price: 79,
                        coupon: null,
                        plan_interval: "monthly",
                        currency_symbol: "€",
                        plan_header: null,
                        product_name: "ORTEX Advanced",
                        plan_desc: "",
                    },
                    {
                        plan_id: "price_1L0nCkINrmKXpRLlG0ptAXUf",
                        plan_currency: "eur",
                        plan_price: 276,
                        coupon: null,
                        plan_interval: "annually",
                        currency_symbol: "€",
                        plan_header: null,
                        product_name: "ORTEX Basic",
                        plan_desc: "",
                    },
                    {
                        plan_id: "price_1L0nBzINrmKXpRLlAlG0eFgb",
                        plan_currency: "eur",
                        plan_price: 29,
                        coupon: null,
                        plan_interval: "monthly",
                        currency_symbol: "€",
                        plan_header: null,
                        product_name: "ORTEX Basic",
                        plan_desc: "",
                    },
                ],
                subscriptions: null,
            } as Subscription);
            return res;
        }
        const res = await axios.get<Subscription>(promoCode ? `/API/v2/user/new_subscription?discount_code=${promoCode}` : "/API/v2/user/new_subscription");

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const cancelSubscription = async () => {
    try {
        if (!process.env.NEXT_PUBLIC_VERCEL_ENV) {
            const res = await Promise.resolve({
                stripe_customer: true,
                cancel_reasons: [
                    {
                        id: 1,
                        reason_text: "I thought it was too expensive",
                    },
                    {
                        id: 3,
                        reason_text: "I did not find the Stocks or ETFs I was looking for",
                    },
                    {
                        id: 4,
                        reason_text: "I did not like the interface",
                    },
                    {
                        id: 2,
                        reason_text: "I did not find the data I was looking for",
                    },
                    {
                        id: 34,
                        reason_text: "I like it, but I'm not trading enough at the moment",
                    },
                ],
                user_types: [
                    {
                        id: 7,
                        description: "Researcher, Student or Journalist",
                    },
                    {
                        id: 1,
                        description: "Day Trader - Full Time",
                    },
                    {
                        id: 5,
                        description: "Dividend Investor",
                    },
                    {
                        id: 3,
                        description: "Short Term Trader",
                    },
                    {
                        id: 4,
                        description: "Occasional Investor",
                    },
                    {
                        id: 2,
                        description: "Day Trading - Occasional",
                    },
                    {
                        id: 6,
                        description: "Long Term Investor",
                    },
                    {
                        id: 34,
                        description: "Don't Know - Still Learning",
                    },
                ],
                subscription_status: "active",
                has_cancelled: true,
                cancellation_date: undefined,
                extension_allowed: false,
                subscription_providers: [],
            } as Cancellation);
            return res;
        }
        const res = await axios.get<Cancellation>("/API/v2/user/cancel");

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const doCancellation = async (body: CancellationBody) => {
    try {
        if (!process.env.NEXT_PUBLIC_VERCEL_ENV) {
            const res = await Promise.resolve({ has_cancelled: true, cancel_date: dayjs().endOf("month").valueOf() });
            return res;
        }
        const res = await axios.post<{ has_cancelled: boolean; cancel_date: number }>("/API/v2/user/cancel", body);

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const abortCancellation = async () => {
    try {
        if (!process.env.NEXT_PUBLIC_VERCEL_ENV) {
            const res = await Promise.resolve({ message: "The cancellation is now aborted and you can continue to use ORTEX." });
            return res;
        }
        const res = await axios.post<{ message: string }>("API/v2/user/abort_cancellation");

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const makePayment = async (body: PaymentBody) => {
    try {
        if (!process.env.NEXT_PUBLIC_VERCEL_ENV) {
            const res = await Promise.resolve({
                subscriptionId: "sub_1L5pFAINrmKXpRLlSjydgiXj",
                clientSecret: "pi_3L5pFBINrmKXpRLl0CHrsE8B_secret_Oh1JsbpRGBUGE0jmkYUGDZKg8",
            } as PaymentResponse);
            return res;
        }
        const res = await axios.post<PaymentResponse>("/API/v2/user/new_subscription", body);

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const cancelBasic = async () => {
    try {
        if (!process.env.NEXT_PUBLIC_VERCEL_ENV) {
            const res = await Promise.resolve();
            return res;
        }
        const res = await axios.post("/API/v2/user/cancel_and_prorate");
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getInfoUser = async () => {
    try {
        const res = await axios.get("/payment/create_subscription");

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getPromo = async () => {
    try {
        if (!process.env.NEXT_PUBLIC_VERCEL_ENV) {
            const res = await Promise.resolve({
                promo_code: "JULY4",
                product_code: "ORTEX Advanced",
                plan_interval: "annually",
                original_price: 588,
                discount_value: 120,
                currency: "usd",
                discount_pc: 20.4,
            });
            return res;
        }
        const res = await axios.get<Promo>("/API/v2/promo");

        return res.data || {};
    } catch (err) {
        throw err;
    }
};

export const getAnnualPrice = async (): Promise<GetAnnualPrice> => {
    try {
        const res = await axios.get<GetAnnualPrice>("/interface/api/user/get_annual_price/", {
            params: null,
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const changeToAnnual = async () => {
    try {
        const res = await axios.post("/interface/api/user/change_to_annual/", null);

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getDiscount = async (code): Promise<any> => {
    try {
        const res = await axios.get(`/interface/api/user/discounts/?interval=${code}`, {
            params: null,
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const applyDiscount = async (code): Promise<any> => {
    try {
        const res = await axios.post(`/interface/api/user/discounts/?interval=${code}`, {
            interval: code,
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};
