import { restartSubscription } from "@/controllers/subscription";
import { isOrtexApp } from "@/lib/utils";
import { useCallback, useEffect, useState } from "react";
import useHandleAppUpgrade from "./useHandleAppUpgrade";
import { useSetRecoilState } from "recoil";
import { paymentModalOpenState, planState, subscriptionState } from "@/lib/store";
import useApplyPromoCode from "./useApplyPromoCode";
import { useRouter } from "next/router";

const useOpenSubscriptionForm = () => {
    const { handleAppUpgrade } = useHandleAppUpgrade();
    const setSubscription = useSetRecoilState(subscriptionState);
    const setPaymentModalOpen = useSetRecoilState(paymentModalOpenState);
    const setPlan = useSetRecoilState(planState);
    const [promoCode, setPromoCode] = useState<string | null>(null);
    const { applyPromoCode } = useApplyPromoCode();

    const { query } = useRouter();

    useEffect(() => {
        const promoCode = query.promo as string;
        if (promoCode) {
            setPromoCode(promoCode);
        }
    }, [query]);

    const interval = (query.pt as string) === "m" ? "monthly" : "annually";

    const openSubscriptionForm = useCallback(
        (shouldOpen = true) => {
            if (!shouldOpen) return;
            if (isOrtexApp()) {
                handleAppUpgrade();
            } else {
                setSubscription(null);
                setPaymentModalOpen(true);
                promoCode
                    ? applyPromoCode(promoCode)
                    : restartSubscription().then((res) => {
                          setSubscription(res);
                          setPlan(res.plans.find((plan) => plan.product_name.includes("Advanced") && plan.plan_interval === interval));
                      });
            }
        },
        [handleAppUpgrade]
    );
    return { openSubscriptionForm };
};

export default useOpenSubscriptionForm;
