import { restartSubscription } from "@/controllers/subscription";
import { toast } from "react-toastify";
import { useState, useRef, useEffect } from "react";
import { useRouter } from "next/router";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { codeAppliedState, paymentModalOpenState, planState, promoCodeDisabledState, showAppSubscriberModalState, subscriptionState, userDataState } from "@/lib/store";

const useApplyPromoCode = () => {
    const [promoCode, setPromoCode] = useState("");
    const setSubscription = useSetRecoilState(subscriptionState);
    const [plan, setPlan] = useRecoilState(planState);
    const userData = useRecoilValue(userDataState);
    const setShowSubscriberDrawer = useSetRecoilState(paymentModalOpenState);
    const setShowAppSubscriberModal = useSetRecoilState(showAppSubscriberModalState);
    const setCodeApplied = useSetRecoilState(codeAppliedState);
    const setPromoCodeDisabled = useSetRecoilState(promoCodeDisabledState);

    const { replace, query } = useRouter();

    const interval = (query.pt as string) === "m" ? "monthly" : "annually";

    useEffect(() => {
        const promoCodeSession = query.promo as string;
        setPromoCode(promoCodeSession);
    }, [query]);

    const applyPromoCode = (codeParam?) => {
        restartSubscription(codeParam ? codeParam : promoCode).then((res) => {
            setSubscription(res);

            const newPlan = plan ? res.plans.find((p) => p.plan_id === plan.plan_id) : res.plans.find((plan) => plan.product_name.includes("Advanced") && plan.plan_interval === interval);
            const discountInfo: any = res.payment_data?.plans?.find((p) => p.id === newPlan?.plan_id);
            setPlan(newPlan);

            if (res.payment_data?.invalid_promo_code) {
                !userData.app_subscriber_only &&
                    toast("This discount code is not valid", {
                        type: "error",
                    });
                setPromoCode("");
            } else {
                !userData.app_subscriber_only &&
                    toast(`${discountInfo.discount_pc}% Discount applied - New price ${newPlan.currency_symbol} ${discountInfo.price}`, {
                        type: "success",
                    });
                if (discountInfo.discount_pc === 100) {
                    toast(`Nothing to pay - Welcome to ORTEX`, {
                        type: "success",
                    });
                    setShowAppSubscriberModal(false);
                    setShowSubscriberDrawer(false);
                    replace("/markets-overview");
                }
                setCodeApplied(codeParam ? codeParam : promoCode);
                setPromoCodeDisabled(true);
            }
        });
    };

    return { applyPromoCode };
};

export default useApplyPromoCode;
