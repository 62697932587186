interface PropTypes {
    number: number;
}

import styles from "./PaymentProgressBar.module.scss";
import { FC, Fragment, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { userState } from "@/lib/store";
import { useRecoilValue } from "recoil";
import { useRouter } from "next/router";

const PaymentProgressBar: FC<PropTypes> = ({ number }) => {
    const [steps, setSteps] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const user = useRecoilValue(userState);

    const { query } = useRouter();

    useEffect(() => {
        const promoCodeSession = query.promo as string;
        if (user?.app_subscriber_only || promoCodeSession?.toUpperCase() === "FEMALEINVEST") {
            setSteps(["Sign Up", "Checkout", "ORTEX"]);
        } else {
            setSteps(["Sign Up", "Choose plan", "Checkout", "ORTEX"]);
        }
    }, [user, query]);

    useEffect(() => {
        setActiveStep(number);
    }, [number]);

    return (
        <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};

                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length && (
                <Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        <Box sx={{ flex: "1 1 auto" }} />
                    </Box>
                </Fragment>
            )}
        </Box>
    );
};

export default PaymentProgressBar;
